// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-external-publications-tsx": () => import("./../../../src/pages/external-publications.tsx" /* webpackChunkName: "component---src-pages-external-publications-tsx" */),
  "component---src-pages-furn-move-tsx": () => import("./../../../src/pages/FurnMove.tsx" /* webpackChunkName: "component---src-pages-furn-move-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ithor-documentation-concepts-tsx": () => import("./../../../src/pages/ithor/documentation/concepts.tsx" /* webpackChunkName: "component---src-pages-ithor-documentation-concepts-tsx" */),
  "component---src-pages-ithor-documentation-environment-state-tsx": () => import("./../../../src/pages/ithor/documentation/environment-state.tsx" /* webpackChunkName: "component---src-pages-ithor-documentation-environment-state-tsx" */),
  "component---src-pages-ithor-documentation-index-tsx": () => import("./../../../src/pages/ithor/documentation/index.tsx" /* webpackChunkName: "component---src-pages-ithor-documentation-index-tsx" */),
  "component---src-pages-ithor-documentation-initialization-tsx": () => import("./../../../src/pages/ithor/documentation/initialization.tsx" /* webpackChunkName: "component---src-pages-ithor-documentation-initialization-tsx" */),
  "component---src-pages-ithor-documentation-interactive-physics-tsx": () => import("./../../../src/pages/ithor/documentation/interactive-physics.tsx" /* webpackChunkName: "component---src-pages-ithor-documentation-interactive-physics-tsx" */),
  "component---src-pages-ithor-documentation-navigation-tsx": () => import("./../../../src/pages/ithor/documentation/navigation.tsx" /* webpackChunkName: "component---src-pages-ithor-documentation-navigation-tsx" */),
  "component---src-pages-ithor-documentation-object-state-changes-tsx": () => import("./../../../src/pages/ithor/documentation/object-state-changes.tsx" /* webpackChunkName: "component---src-pages-ithor-documentation-object-state-changes-tsx" */),
  "component---src-pages-ithor-documentation-objects-domain-randomization-tsx": () => import("./../../../src/pages/ithor/documentation/objects/domain-randomization.tsx" /* webpackChunkName: "component---src-pages-ithor-documentation-objects-domain-randomization-tsx" */),
  "component---src-pages-ithor-documentation-objects-object-types-tsx": () => import("./../../../src/pages/ithor/documentation/objects/object-types.tsx" /* webpackChunkName: "component---src-pages-ithor-documentation-objects-object-types-tsx" */),
  "component---src-pages-ithor-documentation-objects-set-object-states-tsx": () => import("./../../../src/pages/ithor/documentation/objects/set-object-states.tsx" /* webpackChunkName: "component---src-pages-ithor-documentation-objects-set-object-states-tsx" */),
  "component---src-pages-ithor-documentation-scenes-tsx": () => import("./../../../src/pages/ithor/documentation/scenes.tsx" /* webpackChunkName: "component---src-pages-ithor-documentation-scenes-tsx" */),
  "component---src-pages-ithor-index-tsx": () => import("./../../../src/pages/ithor/index.tsx" /* webpackChunkName: "component---src-pages-ithor-index-tsx" */),
  "component---src-pages-manipulathor-documentation-tsx": () => import("./../../../src/pages/manipulathor/documentation.tsx" /* webpackChunkName: "component---src-pages-manipulathor-documentation-tsx" */),
  "component---src-pages-manipulathor-index-tsx": () => import("./../../../src/pages/manipulathor/index.tsx" /* webpackChunkName: "component---src-pages-manipulathor-index-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */),
  "component---src-pages-rearrangement-cvpr-2021-tsx": () => import("./../../../src/pages/rearrangement-cvpr-2021.tsx" /* webpackChunkName: "component---src-pages-rearrangement-cvpr-2021-tsx" */),
  "component---src-pages-rearrangement-tsx": () => import("./../../../src/pages/rearrangement.tsx" /* webpackChunkName: "component---src-pages-rearrangement-tsx" */),
  "component---src-pages-robothor-challenge-tsx": () => import("./../../../src/pages/robothor/challenge.tsx" /* webpackChunkName: "component---src-pages-robothor-challenge-tsx" */),
  "component---src-pages-robothor-cvpr-2021-challenge-tsx": () => import("./../../../src/pages/robothor/cvpr-2021-challenge.tsx" /* webpackChunkName: "component---src-pages-robothor-cvpr-2021-challenge-tsx" */),
  "component---src-pages-robothor-documentation-tsx": () => import("./../../../src/pages/robothor/documentation.tsx" /* webpackChunkName: "component---src-pages-robothor-documentation-tsx" */),
  "component---src-pages-robothor-index-tsx": () => import("./../../../src/pages/robothor/index.tsx" /* webpackChunkName: "component---src-pages-robothor-index-tsx" */)
}

